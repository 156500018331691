import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, ProfileOutlined,} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import {history} from '../../history';
import {logoutAC} from '../../redux/actions';
import FileImageOutlined from "@ant-design/icons/lib/icons/FileImageOutlined";
import './MainLayout.scss';

const {Header, Sider, Content, Footer} = Layout;

class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      selectedKeys: [],
      broken: false,
      detected: false,
    };
  }

  componentDidMount() {
    this.getActivePath();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      this.getActivePath();
    }
  }

  getActivePath = () => {
    const {location: {pathname}} = this.props;
    const paths = `${pathname}`.split('/').filter(item => !!item);
    const selectedKeys = [];
    let path = '';
    paths.forEach(p => {
      path += '/' + p;
      selectedKeys.push(path);
    });
    this.setState({
      selectedKeys,
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  onCollapse = (collapsed, type) => {
    if (type === 'responsive') {
      this.setState({
        collapsed,
      });
    }
  };

  onBreakpoint = (broken) => {
    const state = {
      broken,
      detected: true,
    };
    if (broken) {
      state.collapsed = true;
    }
    this.setState(state);
  };

  handleClick = (event) => {
    if (event.key && event.key !== '/logout') {
      history.push(event.key);
    }
  };

  handleLogout = () => {
    this.props.dispatch(logoutAC());
  };

  render() {
    const {children} = this.props;
    const {selectedKeys, collapsed, broken, detected} = this.state;
    return (
      <Layout className="main-layout" style={{display: detected ? 'block' : 'none'}}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          collapsedWidth={broken ? 0 : 80}
          className="main-sider"
          onCollapse={this.onCollapse}
          onBreakpoint={this.onBreakpoint}
        >
          <div className="main-sider-content">
            <div className="logo">
              {collapsed ? process.env.REACT_APP_SHORT_TEXT_LOGO : process.env.REACT_APP_TEXT_LOGO}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={selectedKeys}
              defaultOpenKeys={['invoices']}
              onClick={this.handleClick}
            >
              <Menu.SubMenu
                key="invoices"
                title={
                  <Fragment>
                    <ProfileOutlined/>
                    <span>Công ty</span>
                  </Fragment>
                }
              >
                <Menu.Item key="/companies/search">
                  <span>Tìm kiếm</span>
                </Menu.Item>
                <Menu.Item key="/companies/import">
                  <span>Import</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="/banner">
                <FileImageOutlined/> <span>Banner</span>
              </Menu.Item>
              <Menu.Item key="/logout" onClick={this.handleLogout}>
                <PoweroffOutlined/> <span>Đăng xuất</span>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
        <Layout className={`main-layout-container ${collapsed ? 'sider-collapsed' : ''}`}>
          <Header
            className="main-header"
            style={{
              background: '#fff',
              padding: 0
            }}>
            {
              collapsed ? <MenuUnfoldOutlined className="trigger" onClick={this.toggle}/> :
                <MenuFoldOutlined className="trigger" onClick={this.toggle}/>
            }
            {/*<div className="header-components">*/}
            {/*  <LoggedInUser*/}
            {/*    user={user}*/}
            {/*    isMobile={broken}*/}
            {/*    handleLogout={this.handleLogout}*/}
            {/*  />*/}
            {/*</div>*/}
          </Header>
          <Content
            style={{
              margin: '0 16px',
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <Footer className="main-footer">
            <p className="copy-right">{process.env.REACT_APP_NAME} (v{process.env.REACT_APP_VERSION}) ©2020. Developed
              by <a href="https://smartbiz.vn" target="_blank" rel="noopener noreferrer">SmartBiz</a>.</p>
          </Footer>
        </Layout>
        {
          broken && !collapsed &&
          <div className="sider-backdrop" onClick={this.toggle}/>
        }
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(MainLayout);
