import React from 'react';
import {message, Progress} from 'antd';
import {updateCompanyFromExcelAPI} from '../../api/import';
import {getErrorMessage} from '../../common/helpers';

class ImportFromExcel extends React.PureComponent {
  state = {
    captchaUrl: '',
    captcha: '',
    totalItems: 0,
    successCount: 0,
  };

  handleImport = async (companies, overwrite = 0) => {
    let i = 0;
    this.setState({
      totalItems: companies.length,
    });
    for (let company of companies) {
      try {
        await updateCompanyFromExcelAPI(company, {params: {overwrite}});
        this.setState({
          successCount: ++i,
        });
      } catch (error) {
        message.error(getErrorMessage(error));
      }
    }
  };

  render() {
    const {totalItems, successCount} = this.state;
    return (
      <div className="import-from-excel">
        {
          !!totalItems &&
          <div className="mb-10" style={{maxWidth: 200}}>
            <Progress type="dashboard" percent={Math.round(successCount * 100 / totalItems)}/>
          </div>
        }
      </div>
    );
  }
}

export default ImportFromExcel;
