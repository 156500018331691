import React, {Fragment} from 'react';
import {Breadcrumb, message} from "antd";
import SearchCompanyForm from "./SearchCompanyForm";
import SearchCompanyResult from "./SearchCompanyResult";
import './SearchCompanies.scss';
import UpdateCompany from "./UpdateCompany";
import {searchCompaniesAPI, updateCompanyAPI} from '../../api/companies';
import {getErrorMessage} from '../../common/helpers';

class SearchCompanies extends React.PureComponent {
  state = {
    company: undefined,
    isShowUpdateForm: false,
    isUpdating: false,
  };

  getCompany = (data) => {
    this.setState({
      company: undefined,
    });
    searchCompaniesAPI(data).then(res => {
      if (res.data) {
        this.setState({
          company: res.data,
        });
      } else {
        this.setState({
          company: null,
        });
        message.error('Không tìm thấy công ty');
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  updateCompany = (data) => {
    const {company} = this.state;
    this.setState({
      isUpdating: true,
    });
    updateCompanyAPI({mst: company.mst}, data).then(() => {
      message.success('Đã lưu');
      this.setState({
        isUpdating: false,
        isShowUpdateForm: false,
      });
      this.getCompany({mst: company.mst});
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isUpdating: false,
      });
    });
  };

  showUpdateForm = () => {
    this.setState({
      isShowUpdateForm: true,
      isUpdating: false,
    });
  };

  toggleShowCompany = () => {
    const {company} = this.state;
    this.setState({
      isUpdating: true,
    });
    updateCompanyAPI({mst: company.mst}, {status: !company.status}).then(() => {
      message.success('Đã lưu');
      this.setState({
        isUpdating: false,
        isShowUpdateForm: false,
      });
      this.getCompany({mst: company.mst});
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isUpdating: false,
      });
    });
  };

  render() {
    const {company, isShowUpdateForm, isUpdating} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Tìm kiếm công ty</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          <div className="search-company-wrapper">
            <SearchCompanyForm handleSearch={this.getCompany}/>
            {
              company !== undefined &&
              <SearchCompanyResult
                company={company}
                isUpdating={isUpdating}
                isShowUpdateForm={isShowUpdateForm}
                showUpdateForm={this.showUpdateForm}
                toggleShowCompany={this.toggleShowCompany}
              />
            }
            {
              isShowUpdateForm &&
              <UpdateCompany
                company={company}
                isUpdating={isUpdating}
                handleUpdate={this.updateCompany}
              />
            }
          </div>
        </div>

      </Fragment>
    );
  }
}

export default SearchCompanies;
