import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const searchCompaniesAPI = (params) => {
  return Requester.get(`${API_URL}/companies/search/`, params);
};

export const updateCompanyAPI = (params, data) => {
  return Requester.post(`${API_URL}/companies/update/`, data, {params});
};
