import React, {Fragment} from 'react';
import {Breadcrumb, Button, message, Table} from "antd";
import UpdateBanner from "./UpdateBanner";
import './Banner.scss';
import {getBannersAPI} from '../../api/banners';
import {getErrorMessage} from '../../common/helpers';

class Banner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      bannerCurrent: '',
      dataTable: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getBannersAPI().then(res => {
      this.setState({
        dataTable: this.handleDataTable(res.data),
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  handleDataTable = (banners) => {
    banners = banners.map(banner => {
      return {...banner, action: 'Sửa', key: banner.id};
    });
    return banners;
  };

  tableColumns = [
    {
      title: 'Tên banner',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      render: (action, banner) => (
        <>
          <Button
            onClick={() => {
              this.setState({
                isModalVisible: true,
                bannerCurrent: banner,
              });
            }}
            type="primary"
          >
            {action}
          </Button>
        </>

      )
    }
  ];

  closeModal = () => {
    this.setState({
      isModalVisible: false
    });
  };

  render() {
    const {dataTable, bannerCurrent, isModalVisible} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Quản lý banner</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          <Table columns={this.tableColumns} dataSource={dataTable}/>
        </div>
        <UpdateBanner
          key={bannerCurrent?.id}
          isShow={isModalVisible}
          banner={bannerCurrent}
          isClose={this.closeModal}
        />
      </Fragment>
    );
  }
}

export default Banner;
