import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const importCompanyAPI = (data) => {
  return Requester.post(`${API_URL}/import/`, data);
};

export const checkCompanyExistedAPI = (data, config = {}) => {
  return Requester.post(`${API_URL}/import/check-existed/`, data, config);
};

export const updateCompanyFromExcelAPI = (data, config = {}) => {
  return Requester.post(`${API_URL}/import/update-excel/`, data, config);
};

export const getImportCaptchaAPI = (data) => {
  return Requester.post(`${API_URL}/import/get-captcha/`, data);
};

export const verifyImportCaptchaAPI = (data) => {
  return Requester.post(`${API_URL}/import/verify-captcha/`, data);
};
