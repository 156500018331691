import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getBannersAPI = () => {
  return Requester.get(`${API_URL}/banners/`);
};

export const updateBannerAPI = (id, data) => {
  return Requester.post(`${API_URL}/banners/update/`, data, {params: {id}});
};
