import React from 'react';
import './AuthLayout.scss';



class AuthLayout extends React.PureComponent {
  render() {
    const {children} = this.props;
    return (
      <div className="auth-layout" style={{backgroundImage: `%PUBLIC_URL%/assets/images/bg-auth-page.jpg`}}>
        <div className="auth-container">
          <div className="auth-form">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default AuthLayout;
