import React from 'react';
import {Button, Form, message, Modal} from "antd";
import RichTextEditor from "../../common/components/RichTextEditor/RichTextEditor";
import {updateBannerAPI} from '../../api/banners';

class UpdateBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      loading: false,
    };
  }

  normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleSubmit = (data) => {
    const {banner} = this.props;
    this.props.isClose();
    updateBannerAPI(banner.id, data).then(() => {
      message.success('Success');
    }).catch(error => {
      message.error(error.message);
    });
  };

  render() {
    const {banner, isShow, isClose} = this.props;
    return (
      <div className="update-banner">
        <Modal
          title={banner.name}
          visible={isShow}
          onCancel={isClose}
          footer={null}
          width={992}
        >
          {
            isShow &&
            <Form
              className='update-banner-form'
              onFinish={this.handleSubmit}
              initialValues={banner}
            >
              <Form.Item
                label="Nội dung"
                name="content"
                rules={[{
                  required: true,
                  message: 'Vui lòng nhập nội dung',
                }]}
              >
                <RichTextEditor/>
              </Form.Item>

              <Form.Item>
                <Button.Group>
                  <Button onClick={isClose}>Hủy</Button>
                  <Button type="primary" htmlType="submit">Cập nhật</Button>
                </Button.Group>
              </Form.Item>

            </Form>
          }
        </Modal>
      </div>
    );
  }
}

export default UpdateBanner;
