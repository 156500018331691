import React from 'react';
import {Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

function UploadFile({value, onChange, multiple = false}) {
  const inputRef = React.useRef();

  const onClick = React.useCallback(function () {
    inputRef.current.click();
  }, []);

  const onInputChange = React.useCallback(function (event) {
    const files = event.target.files;
    if (multiple) {
      onChange(files.length ? files : []);
    } else {
      onChange(files.length ? files[0] : null);
    }
  }, [onChange]);

  return (
    <>
      <Button type="default" icon={<UploadOutlined/>} onClick={onClick}>Chọn file</Button>
      <input
        type="file"
        name="file"
        onChange={onInputChange}
        ref={inputRef}
        style={{display: 'none'}}
      />
      {
        value && !Array.isArray(value) &&
        <div className="file-name mt-10">{value.name}</div>
      }
    </>
  );
}

export default React.memo(UploadFile);
