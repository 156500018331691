import {call, put, takeEvery} from 'redux-saga/effects';
import {history} from '../../../history';
import {
  getCurrentUserAC,
  LOGIN_SUCCESS_AC,
  LOGOUT_AC,
  logoutSuccessAC,
  REFRESH_TOKEN_AC,
  refreshTokenSuccessAC
} from '../../actions';
import {getQueryParams} from '../../../common/helpers';
import {message} from 'antd';
import Store from '../../store';
import {refreshTokenAPI} from '../../../api/auth';

function* loginSuccessFlow() {
  const params = getQueryParams();
  yield put(getCurrentUserAC());
  if (params.redirectTo) {
    history.push(params.redirectTo);
    return;
  }
  history.push('/');
}

function* logoutFlow(action) {
  yield put(logoutSuccessAC());
  if (action.isSessionExpired) {
    message.error('Phiên làm việc hết hạn. Vui lòng đăng nhập lại!');
  }
  history.push('/login');
}

function* refreshToken(action) {
  const {reloadUser} = action;
  const user = Store.getState().user;
  if (!user?.auth?.token?.refresh_token) {
    message.error('Phiên làm việc hết hạn. Vui lòng đăng nhập lại!');
    return;
  }
  const {data} = yield call(refreshTokenAPI, user.auth.token);
  yield put(refreshTokenSuccessAC(data));
  if (reloadUser) {
    yield put(getCurrentUserAC());
  }
}

export default function* () {
  yield takeEvery(LOGIN_SUCCESS_AC, loginSuccessFlow);
  yield takeEvery(LOGOUT_AC, logoutFlow);
  yield takeEvery(REFRESH_TOKEN_AC, refreshToken);
}
