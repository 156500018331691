import React, {Fragment} from 'react';
import {Breadcrumb, Tabs} from 'antd';
import AccountForm from './AccountForm';
import {connect} from 'react-redux';
import {getCurrentUserAC, logoutAC} from '../../redux/actions/user';
import SecurityForm from './SecurityForm';
import './Account.scss';

const {TabPane} = Tabs;

class Account extends React.PureComponent {
  getUser = () => {
    this.props.dispatch(getCurrentUserAC());
  };

  logout = () => {
    this.props.dispatch(logoutAC());
  };

  render() {
    const {user} = this.props;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Tài khoản</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          <Tabs defaultActiveKey="profile" tabPosition="left">
            <TabPane tab="Thông tin tài khoản" key="profile">
              <AccountForm
                user={user}
                onSuccess={this.getUser}
              />
            </TabPane>
            <TabPane tab="Đổi mật khẩu" key="password">
              <SecurityForm
                user={user}
                onSuccess={this.logout}
              />
            </TabPane>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(Account);
