export const updateCurrentUserAPI = () => {

};

export const updatePasswordAPI = () => {

};

export const getCurrentUserAPI = () => {
  return {};
};
