import React, {Fragment} from 'react';
import {Button, Form, Input} from "antd";
import {SaveOutlined} from "@ant-design/icons";

class UpdateCompany extends React.PureComponent {
  render() {
    const {company, isUpdating, handleUpdate} = this.props;
    return (
      <Fragment>
        <Form
          initialValues={company}
          onFinish={handleUpdate}
          className="update-form"
        >
          <Form.Item
            label="Tên công ty"
            name="ten"
            rules={[{
              required: true,
              message: 'Vui lòng nhập tên công ty'
            }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Số điện thoại"
            name="dienthoai"
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Giám đốc"
            name="giamdoc"
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Chủ sở hữu"
            name="chusohuu"
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Địa chỉ chủ sở hữu"
            name="diachichusohuu"
          >
            <Input/>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined/>}
              disabled={isUpdating}
            >
              Lưu thay đổi
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }
}

export default UpdateCompany;
