import qs from 'qs';
import moment from 'moment';
import Store from '../../redux/store/Store';
import {ERROR_MESSAGES} from '../constants/errorMessages';
import React, {Fragment} from 'react';

/**
 * Get public url
 * @param uri
 * @returns {string}
 */
export const publicUrl = (uri) => {
  return `${process.env.PUBLIC_URL}${uri}`;
};

/**
 * Get query params from location.search
 * @param search
 * @param options
 * @return {object}
 */
export const getQueryParams = (search = undefined, options = {}) => {
  if (search === undefined) {
    search = document.location.search;
  }
  return qs.parse(search, {ignoreQueryPrefix: true, ...options});
};

/**
 * Buid query string
 * @param params
 * @param options
 * @return {string|*}
 */
export const buildQueryString = (params, options = {}) => {
  return qs.stringify(params, {options: true, ...options});
};

/**
 * Get error message
 * @param error
 * @returns {*}
 */
export const getErrorMessage = (error) => {
  if (error.response) {
    const {data, status} = error.response;
    if (data && data.metadata) {
      if (status === 400) {
        if (data.metadata.errors && Object.values(data.metadata.errors).length > 0) {
          return Object.values(data.metadata.errors)[0];
        }
      }
      if (ERROR_MESSAGES[data.metadata.code] !== undefined) {
        return ERROR_MESSAGES[data.metadata.code];
      }
      if (data.metadata.message) {
        return data.metadata.message;
      }
    }
  }
  return error.message;
};

/**
 * Check user has permissions or not
 * @param requiredPermissions
 * @param userPermissions
 * @returns {boolean}
 */
export const hasPermissions = (requiredPermissions, userPermissions) => {
  if (!userPermissions) {
    const user = Store.getState().user.info;
    if (!user) {
      return false;
    }
    userPermissions = user.permissions || [];
  }
  if (!userPermissions.length) {
    return false;
  }
  let hasPermission = false;
  if (userPermissions.indexOf(0) >= 0) {
    hasPermission = true;
  } else {
    requiredPermissions.forEach(permissionId => {
      if (userPermissions.indexOf(permissionId) >= 0) {
        hasPermission = true;
      }
    });
  }
  return hasPermission;
};

/**
 *
 * @param requireRoleId
 * @param userRoleId
 * @param operator
 */
export const hasRole = (requireRoleId, userRoleId, operator = '=') => {
  if (!userRoleId) {
    const user = Store.getState().user.info;
    if (!user) {
      return false;
    }
    userRoleId = user.role_id || 0;
  }
  if (operator === '>=') {
    return userRoleId >= requireRoleId;
  } else {
    return userRoleId === requireRoleId;
  }
};

/**
 * Get total page
 * @param totalItems
 * @param pageSize
 * @returns {number}
 */
export const getTotalPage = (totalItems, pageSize) => {
  if (totalItems === 0 || pageSize === 0) {
    return 1;
  }
  return Math.ceil(totalItems / pageSize);
};

export function formatCurrencyValue(value) {
  const arr = `${value}`.split('.');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].replace(/(,*)/g, '');
  }
  arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return arr.join(',');
}

export function parseCurrencyValue(str) {
  const arr = `${str}`.split(',');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].replace(/(\.*)/g, '');
  }
  return arr.join('.');
}

/**
 * Format currency
 */
export const formatCurrency = (number, showPrefix = true) => {
  if (!number || Number.isNaN(number)) {
    if (number === 0 || number === '0') {
      return formatCurrencyValue(number);
    }
    return '';
  }
  if (!showPrefix) {
    return formatCurrencyValue(number);
  }
  return (
    <Fragment>
      {formatCurrencyValue(number)}<sup>đ</sup>
    </Fragment>
  );
};

/**
 * Format date
 */
export const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format(format);
};

/**
 * Check a date string is valid
 * @param date
 * @returns {boolean}
 */
export const isValidDate = (date) => {
  if (!date) {
    return false;
  }
  return moment(date).isValid();
};

export const diffTime = (date, now = null) => {
  if (!date) {
    return 0;
  }
  const date1 = now ? moment(now).endOf('day') : moment();
  const date2 = moment(date);
  return date1.diff(date2);
};

/**
 * Random string
 * @returns {string}
 */
export const randomString = () => {
  return (
    Math.random()
      .toString(36)
      .substr(2, 16)
  );
};

/**
 * Get file extension from file name
 * @param fileName
 * @return {*}
 */
export const getFileExt = (fileName) => {
  const arr = `${fileName}`.split('.');
  let ext = arr.pop();
  if (!ext) {
    return null;
  }
  return ext.toLowerCase();
};

/**
 * Attempts to determine the mime type of a file or blob
 *
 * @param file
 * @returns {Promise<>}
 */
export async function getMimeTypeOfFile(file) {
  const getMimeType = (signature) => {
    switch (signature) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFE1':
        return 'image/jpeg';
      case '504B0304':
        return 'application/zip';
      case '3C3F786D':
        return 'text/xml';
      default:
        return undefined;
    }
  };

  return await new Promise((resolve) => {
    let fileReader = new FileReader();

    fileReader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];

        uint.forEach((byte) => {
          bytes.push(byte.toString(16))
        });

        const hex = bytes.join('').toUpperCase();
        const mimeType = getMimeType(hex);

        resolve(mimeType);
      }
    };

    fileReader.readAsArrayBuffer(file.slice(0, 4));
  });
}

/**
 * Copy to clipboard
 * @param text
 * @param parentEl
 */
export const copyToClipboard = (text, parentEl = null) => {
  if (typeof text === 'object') {
    text = JSON.stringify(text, null, 2);
  }
  const textInput = document.createElement('textarea');
  textInput.innerHTML = text;
  if (!parentEl) {
    parentEl = document.body;
  }
  parentEl.appendChild(textInput);
  textInput.select();
  document.execCommand('copy');
  parentEl.removeChild(textInput);
};

export const byteTo = (byte, to = 'KB') => {
  if (!byte) {
    return 0;
  }
  let result = byte / 1024;
  if (to === 'MB') {
    result = result / 1024;
  }
  if (to === 'GB') {
    result = result / 1024;
  }
  return Math.round(result * 100) / 100;
};

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export function generateGUID() {
  return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}
