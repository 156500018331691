import React from 'react';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';
import {generateGUID} from '../../helpers';

const config = {
  height: 300,
  menubar: false,
  plugins: [
    'advlist autolink lists link image',
    'charmap print preview anchor help',
    'searchreplace visualblocks code',
    'insertdatetime media table paste wordcount'
  ],
  toolbar:
    'undo redo | formatselect | bold italic | \
    alignleft aligncenter alignright | \
    bullist numlist outdent indent | image link | help'
};

class RichTextEditor extends React.PureComponent {
  state = {
    id: generateGUID(),
  };

  render() {
    const {value, onChange, ...rest} = this.props;
    const {id} = this.state;
    return (
      <Editor
        {...rest}
        id={id}
        value={value}
        init={config}
        onEditorChange={onChange}
      />
    );
  }
}

RichTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RichTextEditor;
