import {GET_CURRENT_USER_SUCCESS_AC, LOGIN_SUCCESS_AC, LOGOUT_AC, REFRESH_TOKEN_SUCCESS_AC} from '../../actions';
import createReducer from '../createReducer';
import {getValueFromStorage, removeValueFromStorage, storeValueToStorage} from '../../../common/helpers';

const token = getValueFromStorage('auth', null, 'session');

const initialState = {
  isAuthenticated: false,
  token: token,
};

const handler = {
  [LOGIN_SUCCESS_AC]: (state, action) => {
    storeValueToStorage('auth', action.data.token, 'session');
    return {
      ...state,
      isAuthenticated: true,
      token: action.data.token,
    };
  },
  [LOGOUT_AC]: (state) => {
    removeValueFromStorage('auth', 'session');
    return {
      ...state,
      isAuthenticated: false,
      token: null,
    };
  },
  [GET_CURRENT_USER_SUCCESS_AC]: (state) => ({
    ...state,
    isAuthenticated: true,
  }),
  [REFRESH_TOKEN_SUCCESS_AC]: (state, action) => {
    const token = getValueFromStorage('auth', {}, 'session');
    const newToken = {
      ...token,
      ...action.data.token,
    };
    storeValueToStorage('auth', newToken, 'session');
    return {
      ...state,
      token: newToken,
    };
  },
};

export default createReducer(initialState, handler);
