import React from 'react';
import {Switch} from 'react-router-dom';
import {AppRoute, MainLayout} from './layout';
import Login from './pages/auth/Login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Account from './pages/settings/Account';
import ResetPassword from './pages/auth/ResetPassword/ResetPassword';
import AuthLayout from './layout/AuthLayout/AuthLayout';
import Error404 from './pages/errors/Error404';
import SearchCompanies from './pages/companies/SearchCompanies';
import ImportCompanies from './pages/companies/ImportCompanies';
import Banner from "./pages/banner/Banner";

const Routes = () => (
    <Switch>
      <AppRoute
          path={'/'}
          component={Dashboard}
          layout={MainLayout}
          isPrivate={true}
          exact={true}
      />
      <AppRoute
          path={'/companies/search'}
          component={SearchCompanies}
          layout={MainLayout}
          isPrivate={true}
          exact={true}
      />
      <AppRoute
          path={'/companies/import'}
          component={ImportCompanies}
          layout={MainLayout}
          isPrivate={true}
          exact={true}
      />
      <AppRoute
          path={'/account'}
          component={Account}
          layout={MainLayout}
          isPrivate={true}
          exact={true}
      />
      <AppRoute
          path={'/login'}
          component={Login}
          layout={AuthLayout}
          exact={true}
      />
      <AppRoute
          path={'/reset-password'}
          component={ResetPassword}
          layout={AuthLayout}
          exact={true}
      />
      <AppRoute
          path={'/banner'}
          component={Banner}
          layout={MainLayout}
          exact={true}
      />
      <AppRoute
          component={Error404}
      />
    </Switch>
);

export default Routes;
