import React, {Fragment} from 'react';
import {Button, Col, Divider, Empty, Row} from "antd";

class SearchCompanyResult extends React.PureComponent {
  render() {
    const {company, isUpdating, isShowUpdateForm, showUpdateForm, toggleShowCompany} = this.props;
    return (
      <div className="search-company-result">
        <Divider/>
        <div className="result">
          {
            !company &&
            <Empty description="Không tìm thấy công ty. Vui lòng kiểm tra lại mã số thuế!"/>
          }
          {
            company &&
            <Fragment>
              <h3 className="name">
                {company.ten}
              </h3>
              <Row>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Mã số thuế:</strong>
                    <span>{company.mst}</span>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Địa chỉ:</strong>
                    <span>{company.diachi}</span>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Số điện thoại:</strong>
                    <span>{company.dienthoai}</span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Giám đốc:</strong>
                    <span>{company.giamdoc}</span>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Chủ sở hữu:</strong>
                    <span>{company.chusohuu}</span>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="result-field">
                    <strong>Địa chỉ chủ sỡ hữu:</strong>
                    <span>{company.diachichusohuu}</span>
                  </div>
                </Col>
              </Row>

              <div className="actions">
                <Button
                  className="btn"
                  type="primary"
                  danger={!company.status}
                  disabled={isUpdating}
                  onClick={toggleShowCompany}
                >
                  {company.status ? 'Hiện' : 'Ẩn'}
                </Button>
                {
                  !isShowUpdateForm &&
                  <Button
                    className="btn update-btn"
                    onClick={showUpdateForm}
                    type="primary"
                  >
                    Sửa
                  </Button>
                }
              </div>
            </Fragment>
          }
        </div>
        <Divider/>
      </div>
    );
  }
}

export default SearchCompanyResult;
