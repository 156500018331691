import React from 'react';
import {Button, Form, Input} from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";

class SearchCompanyForm extends React.PureComponent {
  render() {
    const {handleSearch} = this.props;
    return (
      <div className="search-form">
        <Form
          onFinish={handleSearch}
        >
          <Form.Item
            label="Mã số thuế"
            name="mst"
            rules={[{
              required: true,
              message: 'Vui lòng nhập mã số thuế'
            }]}
          >
            <Input placeholder="Nhập mã số thuế ..."/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default SearchCompanyForm;
