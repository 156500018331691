import React, {Fragment} from 'react';
import {Breadcrumb, Button, Form, message, Radio} from "antd";
import UploadFile from '../../common/components/Form/UploadFile';
import ImportFromTCT from './ImportFromTCT';
import {getFileExt} from '../../common/helpers';
import ImportFromExcel from './ImportFromExcel';

const headerMap = new Map();
headerMap.set('mã số thuế', 'mst');
headerMap.set('số điện thoại', 'dienthoai');
headerMap.set('điện thoại', 'dienthoai');
headerMap.set('tên', 'ten');
headerMap.set('tên giao dịch', 'tengiaodich');
headerMap.set('giám đốc', 'giamdoc');
headerMap.set('địa chỉ giám đốc', 'diachigiamdoc');
headerMap.set('chủ sở hữu', 'chusohuu');

class ImportCompanies extends React.PureComponent {
  state = {
    file: '',
    action: 2, // 1 import, 2 update,
    overwrite: 1, // 1 overwrite, 0 not overwrite
    isSubmitting: false,
  };

  importFromTCT = React.createRef();
  importFromExcel = React.createRef();

  onChangeActionField = (e) => {
    this.setState({
      action: e?.target.value
    });
  };

  onChangeOverwriteField = (e) => {
    this.setState({
      overwrite: e.target.value
    });
  };


  onChangeUploadFile = (e) => {
    console.log('Upload: ', e);
  };

  uploadConfig = {
    name: 'file',
    accept: '.txt,.csv',
    fileList: [],
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: () => false,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: ({file, onSuccess}) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  correctTaxCode = (taxCode) => {
    if (taxCode.length < 10) {
      while (taxCode.length < 10) {
        taxCode = '0' + taxCode;
      }
    }
    return taxCode;
  };

  handleOnSubmit = (formData) => {
    // Read file upload
    if (formData && formData.file) {
      const fileExt = getFileExt(formData.file.name);
      if (!['txt', 'csv'].includes(fileExt)) {
        message.error('Invalid file type');
        return;
      }
      const fr = new FileReader();
      fr.onload = (e) => {
        const result = e.target.result;
        const lines = result.split("\n").map(line => line.trim()).filter(line => !!line);
        if (!lines.length) {
          message.error('Empty file');
          return;
        }
        if (fileExt === 'csv') {
          const data = [];
          const header = lines.shift().split(',').map(item => item.trim().toLowerCase()).map(item => {
            const converted = headerMap.get(item);
            return converted || item;
          });
          lines.forEach(line => {
            const arr = line.split(',').map(item => item.trim());
            const company = {};
            arr.forEach((d, i) => {
              if (header[i]) {
                company[header[i]] = d;
              }
            });
            if (!company.mst) {
              return;
            }
            company.mst = this.correctTaxCode(company.mst);
            data.push(company);
          });
          if (!data.length) {
            message.error('No companies found');
            return;
          }
          this.importFromExcel.current.handleImport(data, formData.overwrite);
        } else {
          this.importFromTCT.current.handleImport(lines);
        }
      };
      fr.readAsText(formData.file, 'UTF-8');
    }
  };

  render() {
    const {action, overwrite} = this.state;

    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Import company</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          <Form
            initialValues={{file: [], action: action, overwrite: overwrite}}
            onFinish={this.handleOnSubmit}
          >
            <Form.Item
              label="File (csv, txt)"
              name="file"
              rules={[{
                required: true,
                message: 'Vui lòng chọn file',
              }]}
            >
              <UploadFile/>
            </Form.Item>
            {/*<Form.Item*/}
            {/*  label="Thao tác"*/}
            {/*  name="action"*/}
            {/*  rules={[{*/}
            {/*    required: true,*/}
            {/*    message: 'Vui lòng chọn thao tác'*/}
            {/*  }]}*/}
            {/*>*/}
            {/*  <Radio.Group onChange={this.onChangeActionField} value={1}>*/}
            {/*    <Radio value={1}>Thêm mới</Radio>*/}
            {/*    <Radio value={2}>Cập nhật</Radio>*/}
            {/*  </Radio.Group>*/}
            {/*</Form.Item>*/}
            {
              action && action === 2 &&
              <Form.Item
                label="Ghi đè số điện thoại đã có"
                name="overwrite"
                rules={[{
                  required: true,
                  message: 'Vui lòng chọn thao tác'
                }]}
              >
                <Radio.Group onChange={this.onChangeOverwriteField} value={1}>
                  <Radio value={1}>Ghi đè</Radio>
                  <Radio value={0}>Không ghi đè</Radio>
                </Radio.Group>
              </Form.Item>
            }

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Import
              </Button>
            </Form.Item>

          </Form>

          <ImportFromTCT ref={this.importFromTCT}/>
          <ImportFromExcel ref={this.importFromExcel}/>

        </div>
      </Fragment>
    );
  }
}

export default ImportCompanies;
