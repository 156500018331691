import React from 'react';
import {checkCompanyExistedAPI, getImportCaptchaAPI, importCompanyAPI, verifyImportCaptchaAPI} from '../../api/import';
import {Input, message, Progress} from 'antd';
import {getErrorMessage} from '../../common/helpers';

class ImportFromTCT extends React.PureComponent {
  state = {
    captchaUrl: '',
    captcha: '',
    totalItems: 0,
    successCount: 0,
  };

  dataFolder = '';
  captchaResolve = null;
  captchaReject = null;

  handleImport = async (taxCodes) => {
    let i = 0;
    this.setState({
      totalItems: taxCodes.length,
    });
    for (let taxCode of taxCodes) {
      try {
        const {data} = await checkCompanyExistedAPI(null, {params: {mst: taxCode}});
        if (data && data.dienthoai) {
          this.setState({
            successCount: ++i,
          });
          continue;
        }
        await this.handleImportAsync(taxCode);
        this.setState({
          successCount: ++i,
        });
      } catch (error) {
        message.error(getErrorMessage(error));
      }
    }
  };

  handleImportAsync = async (taxCode, isRetry = false) => {
    taxCode = typeof taxCode === 'object' ? taxCode.taxCode : taxCode;
    if (!taxCode) {
      throw new Error('mst is required');
    }
    try {
      await importCompanyAPI({source: 'tct', taxCode, dataFolder: this.dataFolder});
    } catch (error) {
      if (error?.response?.data?.dataFolder) {
        this.dataFolder = error.response.data.dataFolder;
      }
      if (!isRetry) {
        await this.handleVerifyCaptcha();
        await this.handleImportAsync(taxCode, true);
      } else {
        throw error;
      }
    }
  };

  handleVerifyCaptcha = async () => {
    return new Promise(async (resolve, reject) => {
      this.captchaResolve = resolve;
      this.captchaReject = reject;
      this.getCaptcha();
    });
  };

  getCaptcha = () => {
    getImportCaptchaAPI({
      source: 'tct',
      dataFolder: this.dataFolder,
    }).then(res => {
      this.setState({
        captcha: '',
        captchaUrl: res.captcha,
      });
    }).catch((error) => {
      if (typeof this.captchaReject === 'function') {
        this.captchaReject(error);
      }
    });
  };

  onCaptchaChanged = (event) => {
    this.setState({
      captcha: event.target.value,
    });
  };

  onSubmit = (event) => {
    const {captcha} = this.state;
    event.preventDefault();
    if (captcha) {
      verifyImportCaptchaAPI({
        source: 'tct',
        dataFolder: this.dataFolder,
        captcha,
      }).then(() => {
        if (typeof this.captchaResolve === 'function') {
          this.captchaResolve();
          this.setState({
            captcha: '',
            captchaUrl: '',
          });
        }
      }).catch(() => {
        this.getCaptcha();
      });
    }
  };

  render() {
    const {captchaUrl, captcha, totalItems, successCount} = this.state;
    return (
      <form className="verify-captcha" onSubmit={this.onSubmit}>
        {
          !!totalItems &&
          <div className="mb-10" style={{maxWidth: 200}}>
            <Progress type="dashboard" percent={Math.round(successCount * 100 / totalItems)}/>
          </div>
        }
        {
          captchaUrl &&
          <>
            <img src={captchaUrl} alt="Captcha"/>
            <div className="mt-10" style={{maxWidth: 200}}>
              <Input
                autoFocus={true}
                value={captcha}
                onChange={this.onCaptchaChanged}
              />
            </div>
          </>
        }
      </form>
    );
  }

}

export default ImportFromTCT;
